import { render, staticRenderFns } from "./formBlock.vue?vue&type=template&id=393ff40e&scoped=true"
import script from "./formBlock.vue?vue&type=script&lang=js"
export * from "./formBlock.vue?vue&type=script&lang=js"
import style0 from "./formBlock.vue?vue&type=style&index=0&id=393ff40e&prod&style=scss&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "393ff40e",
  null
  
)

export default component.exports